import { Device, DeviceInfo } from '@capacitor/device'

interface IDeviceCapacitor {
  getDevicePlatformInfo: () => Promise<{ os: string, platform: string, isMobile: boolean }>
  getDeviceInfo: () => Promise<DeviceInfo>
}

export default class DeviceCapacitor implements IDeviceCapacitor {
  async getDevicePlatformInfo () {
    const { operatingSystem, platform } = await this.getDeviceInfo()
    return ({ os: operatingSystem, platform, isMobile: platform !== 'web' })
  }

  async getDeviceInfo () {
    return await Device.getInfo()
  }
}
